<template>
    <div class="note_card">
        <div class="note_body">
            <slot></slot>
        </div>
        <div class="dropdown" @click="dropOption = !dropOption" v-click-outside="closeDropOption">
            <i class="fas fa-ellipsis-v"></i>
            <div class="drp_wrapper" v-if="dropOption" :class="{active : dropOption}">
                <ul>
                    <li v-for="action in actions" :key="action.label" @click="actionHandler(selectedRow, action.action)">
                        <i :class="`fas ${action.icon} mr-1`"></i>{{ action.label }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Profile Card',

        data(){
            return{
                dropOption: false,
            }
        },

        props: {
            actions: Object,
            actionHandler: Function,
            selectedRow: Object,
        },

        methods: {
            closeDropOption () {
                const vm = this;
                vm.dropOption = false;
            }
        }
}
</script>
